import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import { Cookies } from 'quasar'
import User from '../store/models/User'

import moment from 'moment'

Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function ({ store, ssrContext }) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  Router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies

      const token = cookies.get('token')

      if (!token || typeof token === 'undefined') {
        next({
          path: '/auth/login',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  })

  // Customerly data
  Router.beforeEach((to, from, next) => {
    const ssr = process.env.SERVER

    if (!ssr && window.customerly) {
      const user = User.me()

      let subscription = null
      if (user && user.company && user.company.subscriptions && user.company.subscriptions.length) {
        subscription = user.company.subscriptions.sort((subA, subB) => {
          if (subA.id < subB.id) {
            return -1
          }
          if (subA.id > subB.id) {
            return 1
          }
          return 0
        })[0]
      }

      const data = {
        app_id: 'f79a68dc',
        user_id: user ? user.id : null, // Optional
        name: user ? user.name : null,
        email: user ? user.email : null,
        // Properties can be added here as you wish
        attributes: {
          company_name: user && user.company ? user.company.name : null,
          subscription_type: (subscription) ? subscription.name : null,
          subscription_id: (subscription) ? subscription.stripe_id : null,
          subscription_status: (subscription) ? subscription.stripe_status : null,
          subscription_expire_at: (subscription) ? subscription.current_period_end : null,
          newsletter: user && user.newsletter,
          created_at: user && user.created_at
        }
      }
      if (window.customerly.load) {
        window.customerly.load(data)
      } else {
        window.customerly.update(data)
      }
    }
    next()
  })

  Router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.redirectIfAuthenticated)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      const ssr = process.env.SERVER
      const cookies = ssr ? Cookies.parseSSR(ssrContext) : Cookies
      const token = cookies.get('token')

      if (token) {
        next({
          path: '/app/projects'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  })

  Router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.redirectIfSubscribed)) {
      if (User.me()) {
        const subscribed = User.me().company.subscribed || (User.me().company.trial_ends_at && moment(User.me().company.trial_ends_at).isAfter(moment()))

        if (subscribed) {
          next({
            path: '/app/projects'
          })
        } else {
          next()
        }
      } else {
        User.$getMe()
          .then((response) => {
            const subscribed = User.me().company.subscribed || (User.me().company.trial_ends_at && moment(User.me().company.trial_ends_at).isAfter(moment()))

            if (subscribed) {
              next({
                path: '/app/projects'
              })
            } else {
              next()
            }
          })
          .catch(() => {
            next()
          })
      }
    } else {
      next()
    }
  })

  Router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireSubscription)) {
      // this route requires subscription, check if logged in
      // if not, redirect to subscribe page.
      User.$getMe()
        .then((response) => {
          const user = User.me()
          const subscribed = user.company.subscribed || (user.company.trial_ends_at && moment(user.company.trial_ends_at).isAfter(moment()))

          if (!subscribed) {
            next({
              path: '/app/subscribe'
            })
          } else {
            next()
          }
        })
        .catch(() => {
          next({
            path: '/app/subscribe'
          })
        })
    } else {
      next()
    }
  })

  return Router
}
