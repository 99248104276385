// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  home: {
    subtitle: 'calcolare la superficie commerciale<br />delle planimetrie<br />non è mai stato più semplice'
  }
}
