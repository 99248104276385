
import Vuex from 'vuex'
import Vue from 'vue'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import database from './models'
import app from './modules/app'

VuexORM.use(VuexORMAxios, {
  database,
  axios: Vue.prototype.$axios
})

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function (/* { ssrContext } */) {
  return new Vuex.Store({
    namespaced: true,
    modules: {
      app
    },
    plugins: [VuexORM.install(database)]
  })
}
