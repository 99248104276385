// Project Model
import Vue from 'vue'
import VuexORM from '@vuex-orm/core'
import Company from './Company'
import Page from './Page'

export default class Project extends VuexORM.Model {
  // This is the name used as module name of the Vuex Store.
  static get entity () {
    return 'projects'
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      square_meter_price: this.attr(null),
      value_notes: this.attr(''),
      company_id: this.attr(''),
      company: this.belongsTo(Company, 'company_id'),
      pages: this.hasMany(Page, 'project_id', 'id')
    }
  }

  $delete () {
    return Vue.prototype.$axios
      .delete(`/api/companies/${this.company_id}/projects/${this.id}`)
      .then(() => {
        Project.delete(this.id)
      })
  }
}
