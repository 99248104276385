// User Model
import Vue from 'vue'
import VuexORM from '@vuex-orm/core'
import Company from './Company'
import moment from 'moment'
import { i18n } from '../../boot/i18n'

export default class Subscription extends VuexORM.Model {
  // This is the name used as module name of the Vuex Store.
  static get entity () {
    return 'subscriptions'
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      company_id: this.attr(null),
      company: this.belongsTo(Company, 'company_id'),
      name: this.attr(''),
      stripe_id: this.attr(''),
      stripe_status: this.attr(''),
      ends_at: this.attr(''),
      trial_ends_at: this.attr(''),
      created_at: this.attr(''),
      current_period_end: this.attr(''),
      plan: this.attr('')
    }
  }

  get statusName () {
    switch (this.stripe_status) {
      case 'trialing': return i18n.t('app.subscriptions.status.trialing')
      case 'active': return i18n.t('app.subscriptions.status.active')
      case 'incomplete': return i18n.t('app.subscriptions.status.incomplete')
      case 'incomplete_expired': return i18n.t('app.subscriptions.status.incomplete_expired')
      case 'past_due': return i18n.t('app.subscriptions.status.past_due')
      case 'canceled': return i18n.t('app.subscriptions.status.canceled')
      case 'unpaid': return i18n.t('app.subscriptions.status.unpaid')
    }
    return ''
  }

  get formattedCreation () {
    return moment(this.created_at).format('DD/MM/YYYY HH:mm')
  }

  get formattedPeriodEnd () {
    return moment(this.current_period_end).format('DD/MM/YYYY HH:mm')
  }

  static $cancelSubscription (subscription) {
    return Vue.prototype.$axios.put('/api/subscriptions/' + subscription.id + '/cancel')
      .then((response) => {
        Subscription.insert({
          data: response.data
        })
      })
  }
}
