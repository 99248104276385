import { mapState } from 'vuex'

export default {
  namespaced: true,
  state: {
    viewName: 'AreaPlan',
    userIsSubscribed: false,
    accessToken: null,
    locale: 'it'
  },
  computed: mapState([
    // map this.count to store.state.count
    'accessToken',
    'locale'
  ]),
  mutations: {
    setViewName (state, payload) {
      state.viewName = payload
    },
    setUserIsSubscribed (state, subscribed) {
      state.userIsSubscribed = subscribed
    },
    setAccessToken (state, accessToken) {
      state.accessToken = accessToken
    }
  }
}
