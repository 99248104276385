import axios from 'axios'

import { Notify, Cookies } from 'quasar'
import { first } from 'lodash'

export default async ({ Vue, store, ssrContext }) => {
  Vue.prototype.$axios = axios.create({
    baseURL: process.env.SERVER_URL
  })

  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies

  Vue.prototype.$axios.interceptors.request.use(config => {
    const token = cookies.get('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }, error => {
    return Promise.reject(error)
  })

  Vue.prototype.$axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 422) {
        Notify.create(first(error.response.data.errors[first(Object.keys(error.response.data.errors))]))
      } else if (error.response.status === 412) {
        // Do nothing
      } else if (error.response.status === 402) {
        // Do nothing
      } else if (error.response.status === 401) {
        cookies.remove('token')
        if (error.response.request.responseURL.match('oauth/token')) {
          Notify.create({
            color: 'warning',
            textColor: 'dark',
            timeout: 3000,
            message: 'Email o password non valide'
          })
        } else {
          if (error.response.data.message === 'Unauthenticated.') {
            Notify.create({
              color: 'warning',
              textColor: 'dark',
              timeout: 3000,
              message: 'La sessione è scaduta oppure qualcuno ha fatto accesso con il tuo account da un altro dispositivo',
              onDismiss: () => {
                window.location.href = '/'
              }
            })
          } else {
            Notify.create({
              timeout: 3000,
              color: 'warning',
              textColor: 'dark',
              message: 'Qualcosa è andato storto riprova',
              onDismiss: () => {
                window.location.href = '/'
              }
            })
          }
        }
      } else {
        Notify.create('Qualcosa è andato storto, riprova')
      }
      return Promise.reject(error)
    })
}
