// Project Model
import VuexORM from '@vuex-orm/core'
import Project from './Project'

export default class Page extends VuexORM.Model {
  // This is the name used as module name of the Vuex Store.
  static get entity () {
    return 'pages'
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      canvas: this.attr(''),
      canvas_image: this.attr(''),
      background_image: this.attr(''),
      background_image_res: this.attr(null),
      background_image_url: this.attr(''),
      project_id: this.number(0),
      project: this.belongsTo(Project, 'project_id')
    }
  }
}
