import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import User from './User'
import Project from './Project'
import Page from './Page'
import Company from './Company'
import Subscription from './Subscription'
import Invoice from './Invoice'
import app from '../modules/app'

Vue.use(Vuex)

// Create new instance of Database.
const database = new VuexORM.Database()

// Register Model and Module. The First argument is the Model, and
// second is the Module.
database.register(Company, app)
database.register(User, app)
database.register(Subscription, app)
database.register(Project, app)
database.register(Page, app)
database.register(Invoice, app)

export default database
