const routes = [
  {
    path: '/',
    component: () => import('layouts/SiteLayout.vue'),
    children: [
      {
        name: 'home',
        path: '',
        component: () => import('pages/Index.vue')
      },
      {
        name: 'terms',
        path: 'terms-and-conditions',
        component: () => import('pages/Terms.vue')
      }
    ]
  },

  {
    path: '/auth/',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {
        name: 'signup',
        path: 'signup',
        component: () => import('pages/auth/Signup.vue'),
        meta: {
          redirectIfAuthenticated: true
        }
      },
      {
        name: 'login',
        path: 'login',
        component: () => import('pages/auth/Login.vue'),
        meta: {
          redirectIfAuthenticated: true
        }
      },
      {
        name: 'password_reset',
        path: 'password-reset',
        component: () => import('pages/auth/PasswordReset.vue')
      },
      {
        path: 'password-reset-request',
        name: 'password-reset-request',
        component: () => import('pages/auth/PasswordResetRequest.vue'),
        meta: {
          redirectIfAuthenticated: true
        }
      },
      {
        path: 'password-reset-requested',
        name: 'password-reset-requested',
        component: () => import('pages/auth/PasswordResetRequested.vue'),
        meta: {
          redirectIfAuthenticated: true
        }
      }
    ]
  },
  {
    path: '/app',
    meta: {
      auth: true
    },
    component: () => import('layouts/AppLayout.vue'),
    children: [
      {
        name: 'projects',
        path: 'projects',
        component: () => import('pages/app/Projects/index.vue'),
        meta: {
          requireSubscription: true
        }
      },
      {
        name: 'new_project',
        path: 'projects/create',
        component: () => import('pages/app/Projects/Edit.vue'),
        meta: {
          requireSubscription: true
        }
      },
      {
        name: 'project_edit',
        path: 'projects/:id',
        component: () => import('pages/app/Projects/Edit.vue'),
        meta: {
          requireSubscription: true
        }
      },
      {
        name: 'subscribe',
        path: 'subscribe',
        component: () => import('pages/app/Subscribe.vue'),
        meta: {
          redirectIfSubscribed: true
        }
      },
      {
        name: 'checkout-success',
        path: 'checkout-success',
        component: () => import('pages/app/CheckoutSuccess.vue')
      }
    ]
  },
  {
    path: '/app',
    meta: {
      auth: true
    },
    component: () => import('layouts/BlankLayout.vue'),
    children: [
      {
        name: 'project_print',
        path: 'projects/:id/print',
        component: () => import('pages/app/Projects/Print.vue'),
        meta: {
          requireSubscription: true
        }
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
