// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Azione fallita',
  success: 'Azione eseguita con successo',
  validation: {
    required: 'Il campo {field} è richiesto',
    required_if: 'Il campo {first_field} è richiesto se non è presente il campo {second_field}',
    regex: 'Il campo {field} non è nel formato corretto',
    confirmed: 'I campi {field} non corrispondono',
    min: 'Il campo {field} deve essere lungo almeno {size} caratteri'
  },
  generals: {
    try_for_free: 'Provalo gratis',
    complete_fields: 'Prima di salvare compila tutti i campi obbligatori',
    full_name: 'Nome e Cognome',
    company: 'Società',
    email: 'E-mail',
    password: 'Password',
    password_confirmation: 'Conferma password',
    cancel: 'Annulla',
    back: 'Indietro',
    close: 'Chiudi',
    save: 'Salva',
    profile_updated: 'Profilo aggiornato!',
    project_created: 'Progetto creato!',
    project_updated: 'Progetto aggiornato!',
    password_updated: 'Password cambiata correttamente!'
  },
  home: {
    subtitle: 'calcolare la superficie commerciale<br />delle planimetrie<br />non è mai stato più semplice',
    caption: 'funziona anche quando le planimetrie non sono in scala',
    captions: {
      1: 'carica il file della planimetria',
      2: 'traccia il perimetro cliccando gli spigoli',
      3: 'imposta una sola misura col marcatore di scala'
    },
    done: 'Fatto!',
    claims: {
      1: 'Sempre a portata di mano',
      2: 'Facile ed intuitivo',
      3: 'Veloce ed economico'
    },
    start_free_evaluation: 'Inizia ora la prova gratuita'
  },
  auth: {
    free_trial: 'Prova gratuita di 14 giorni',
    bullet_1: 'Registrazione rapida',
    bullet_1_caption: 'Bastano il tuo nome, una email e una password, crea il tuo account in 1 minuto!',
    bullet_2: 'Calcola l\'area della tua planimetria in 2 minuti',
    bullet_2_caption: 'Ti guideremo passo dopo passo al calcolo della tua prima area',
    bullet_3: 'Abbonati per salvare le planimetrie',
    bullet_3_caption: 'Con il tuo account Areaplan potrai salvare le tue planimetrie e stamparle in qualsiasi momento',
    start_free_trial: 'prova gratuita',
    accept_privacy_and_terms_conditions: 'Ho letto ed accetto i <a class="text-accent text-weight-bold q-mx-xs" href="/terms-and-conditions" target="_blank">Termini e le condizioni d\'uso</a> e la &nbsp;<a href="https://www.iubenda.com/privacy-policy/73789630" class="iubenda-white no-brand iubenda-embed" title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script> di AreaPlan.',
    cookies: 'Scopri in che modo utilizziamo i cookies nella nostra &nbsp;<a href="https://www.iubenda.com/privacy-policy/73789630/cookie-policy" class="iubenda-white no-brand iubenda-embed" title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
    create_account: 'Crea il tuo account',
    already_have_account: 'Hai già un account?',
    dont_have_account: 'Non hai un account?',
    cant_login: 'Password dimenticata?',
    registration_email: 'Inserisci la tua email di registrazione',
    registration_email_hint: 'Verrà inviata una e-mail a questo indirizzo con il link per ripristinare la password',
    back_to_login: 'Torna al login',
    login: 'accedi',
    signup: 'registrati!',
    reset_password: 'cambia password',
    save_new_password: 'Salva password'
  },
  newsletter: {
    subscribed: 'Sottoscrizione alla newsletter attivata',
    unsubscribed: 'Disiscrizione alla newsletter riuscita'
  },
  app: {
    project: {
      name: 'Nome progetto',
      save: 'Salva',
      create: 'Crea nuovo progetto'
    },
    toolbar: {
      distance: 'Distanza',
      scale: 'Imposta Misura Reale',
      scale_dialog_header: 'Marcatore di scala',
      add_scale_tooltip: 'Per impostare la scala: clicca su un punto A, e tenendo premuto il mouse, trascina fino a un punto B, rilascia e inserisci la misura reale.',
      zoom_in_tooltip: 'Ingrandisci',
      zoom_out_tooltip: 'Rimpicciolisci',
      calculate_area_tooltip: 'Calcola la superficie delle aree disegnate',
      print_result_tooltip: 'Stampa',
      value_tooltip: 'Note su stampa',
      measure_tooltip: 'Misura la distanza tra due punti',
      drag_tooltip: 'Trascina',
      select_tooltip: 'Seleziona e modifica',
      percentage_tooltip: 'Disegna poligono con area calcolata al {percentage}%',
      cancel_action_tooltip: 'Annulla azione',
      save: 'Salva il progetto',
      labels: {
        labels_visible: 'Grandi con sfondo bianco',
        labels_transparent: 'Grandi con sfondo trasparente',
        labels_visible_small: 'Piccole con sfondo bianco',
        labels_transparent_text_only: 'Piccole con sfondo trasparente',
        labels_hidden: 'Nascoste'
      }
    },
    percentages: {
      100: 'Area rossa',
      80: 'Area bordeaux',
      75: 'Area violetto',
      60: 'Area blu scuro',
      50: 'Area indaco',
      40: 'Area verde',
      33: 'Area gialla',
      30: 'Area giallo scuro',
      25: 'Area azzurra',
      20: 'Area lilla',
      15: 'Area beige',
      10: 'Area verde scuro',
      5: 'Area verde chiaro',
      2: 'Area arancio'
    },
    menu: {
      settings: 'Impostazioni account',
      saved_documents: 'Planimetrie salvate',
      logout: 'Esci'
    },
    add_planimetry: 'aggiungi altra planimetria a questo progetto',
    print_button_text: 'Stampa',
    new_button_text: 'Nuovo',
    page: 'Pagina {page}',
    upload: 'Carica una planimetria',
    upload_format: '(in formato pdf, jpeg, png)',
    invalid_scale: 'Inserisci una scala pi',
    add_scale_info: 'aggiungi la scala per calcolare l\'area',
    add_scale_value_info: 'dai un valore alla scala per poter calcolare l\'area',
    calculate_info: 'clicca su "Calcola" per ottenere l\'area complessiva',
    invalid_uploaded_file: 'Formato del file non valido',
    calculated_at: 'calcolata al {percentage}%',
    press_esc_to_cancel: 'Premi ESC per annullare o fai DOPPIO CLICK per chiudere il poligono',
    subscriptions: {
      title: 'Abbonamento',
      name: 'AreaPlan PRO',
      email: 'E-mail',
      remaining_trial_days: 'Il periodo di prova è scaduto - ABBONATI QUI | Rimane solo un giorno di prova - ABBONATI QUI | Rimangono {days} giorni di prova - ABBONATI QUI',
      invoice_sent: 'Fattura inviata tramite email',
      no_invoices: 'Nessuna fattura trovata',
      per_year: '{price} all\'anno',
      vat_included: 'IVA inclusa',
      paid_today: 'Importo addebitato oggi',
      next_charge: 'Il prossimo addebito sarà di {amount} il <b>{date}</b>',
      accept_terms: 'Cliccando il bottone sottostante, accetti i <a href="/terms-and-conditions" target="_blank">Termini e le condizioni d’uso</a> e le <a href="https://www.iubenda.com/privacy-policy/73789630" class="iubenda-white no-brand iubenda-embed" title="Privacy Policy" target="_blank">Privacy Policy</a>',
      save_and_purchase: 'Aggiorna i dati e procedi con l\'acquisto',
      purchase: 'Acquista l\'abbonamento',
      profile_data: 'Profilo utente',
      login_data: 'Dati di accesso',
      invoice_data: 'Dati di fatturazione',
      invoices: 'Fatture',
      invoice_email: 'Email per la fatturazione',
      marketing_emails: 'Accetto di ricevere proposte commerciali o di servizi da parte della stessa società',
      marketing_emails_nb: 'N.B I dati verranno usati solo per uso interno e non verranno mai ceduti a terzi',
      address: 'Indirizzo',
      zip_code: 'CAP',
      city: 'Città',
      state: 'Provincia',
      country: 'Stato',
      taxcode: 'Codice Fiscale',
      vat_id: 'Partita IVA',
      sdi: 'SDI',
      pec: 'PEC',
      pec_hint: 'Da compilare solo se non si possiede uno SDI',
      credit_card: 'Carta di credito',
      credit_card_number: 'Numero della carta di credito',
      add_new_card: 'Aggiungi nuova carta',
      update_card: 'Aggiorna carta',
      remove_card: 'Rimuovi carta',
      card_removed: 'Carta rimossa con successo',
      card_owner: 'Proprietario della carta',
      expiration: 'Scadenza',
      cvc: 'CVC',
      purchase_success: 'Abbonamento acquistato correttamente. Grazie!',
      status: {
        title: 'stato',
        trialing: 'In prova',
        active: 'Attivo',
        incomplete: 'Pagamento incompleto',
        incomplete_expired: 'Pagamento incompleto scaduto',
        past_due: 'Scaduto',
        canceled: 'Annullato',
        unpaid: 'Non pagato'
      },
      active_sub: 'Abbonamenti attivi',
      created_at: 'creato il',
      renewal_date: 'rinnovo automatico il',
      expiration_date: 'termina il',
      cancel: 'Annulla abbonamento',
      cancel_confirmation_message: 'Sei sicuro di voler annullare l\'abbonamento?',
      cancel_success: 'Abbonamento annullato correttamente',
      cancel_success_message: 'Potrai usare AreaPlan PRO fino alla scadenza della licenza e l\'abbonamento non sarà più rinnovato automaticamente.',
      company_logo: 'Logo aziendale'
    }
  }
}
