// User Model
import VuexORM from '@vuex-orm/core'
import Vue from 'vue'
import { Cookies } from 'quasar'
import Company from './Company'
import Project from './Project'

export default class User extends VuexORM.Model {
  // This is the name used as module name of the Vuex Store.
  static get entity () {
    return 'users'
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      email: this.attr(''),
      me: this.attr(''),
      newsletter: this.boolean(true),
      company_id: this.attr(''),
      company: this.belongsTo(Company, 'company_id'),
      projects: this.hasMany(Project, 'user_id')
    }
  }

  static $login (credentials) {
    return Vue.prototype.$axios.post('/oauth/token', {
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
      grant_type: 'password',
      username: credentials.email,
      password: credentials.password
    }).then((response) => {
      Cookies.set('token', response.data.access_token, { path: '/' })
      Vue.prototype.$axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.access_token
    })
  }

  static $logout () {
    Cookies.remove('token', { path: '/' })
    Vue.prototype.$axios.defaults.headers.common.Authorization = ''
  }

  static $register (credentials) {
    return Vue.prototype.$axios.post('/api/register', {
      name: credentials.name,
      company: credentials.company,
      email: credentials.email,
      password: credentials.password,
      password_confirmation: credentials.password_confirmation,
      tos: credentials.terms_accepted,
      locale: 'it'
    }).then((response) => {
      Cookies.set('token', response.data.access_token, { path: '/' })
      Vue.prototype.$axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.access_token
    })
  }

  static $resetPasswordRequest (email) {
    return Vue.prototype.$axios.post('/api/password/reset-request', {
      email: email
    })
  }

  static $resetPassword (data) {
    return Vue.prototype.$axios.post('/api/password/reset', data)
  }

  static $getMe (credentials) {
    return Vue.prototype.$axios.get('/api/me').then((response) => {
      User.insert({
        data: response.data
      })
      // window.bugsnagClient.user = {
      //   id: response.data.id,
      //   name: response.data.name,
      //   email: response.data.email
      // }
    }).catch((error) => {
      console.error(error)
    })
  }

  static $getUserFromResetPasswordToken (token) {
    return Vue.prototype.$axios.get('/api/users/token/' + token).catch((error) => {
      console.error(error)
    })
  }

  $updateCustomerlyUser (attributes) {
    const data = {
      app_id: 'f79a68dc',
      user_id: this.id, // Optional
      name: this.name,
      email: this.email,
      attributes: attributes
    }
    window.customerly.update(data)
  }

  static me () {
    return User.query().withAllRecursive().where('me', true).first()
  }
}
