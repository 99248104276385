// User Model
import VuexORM from '@vuex-orm/core'
import Vue from 'vue'
import Subscription from './Subscription'

export default class Company extends VuexORM.Model {
  // This is the name used as module name of the Vuex Store.
  static get entity () {
    return 'companies'
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      vat_id: this.attr(''),
      email: this.attr(''),
      image: this.attr(''),
      image_url: this.attr(''),
      taxcode: this.attr(''),
      sdi: this.attr('00000000'),
      pec: this.attr(''),
      address: this.attr(''),
      zip_code: this.attr(''),
      city: this.attr(''),
      state: this.attr(''),
      country: this.attr(''),
      stripe_id: this.attr(''),
      card_brand: this.attr(''),
      card_last_four: this.attr(''),
      trial_ends_at: this.attr(''),
      subscribed: this.boolean(false),
      subscriptions: this.hasMany(Subscription, 'company_id')
    }
  }

  get dataCompleted () {
    return this.name.length && (this.taxcode || this.vat_id) && (this.pec || this.sdi) && this.address && this.zip_code && this.city && this.state && this.country && this.card_last_four
  }

  $getStripeSetupIntent () {
    return Vue.prototype.$axios.get('/api/companies/' + this.id + '/get-stripe-setup-intent')
  }

  $updatePaymentMethod (paymentMethod) {
    return Vue.prototype.$axios
      .post('/api/companies/' + this.id + '/update-payment-method', { payment_method: paymentMethod })
      .then((response) => {
        Company.update({
          where: this.id,
          data: {
            card_brand: response.data.company.card_brand,
            card_last_four: response.data.company.card_last_four
          }
        })
        return response.data
      })
  }

  $removePaymentMethod () {
    return Vue.prototype.$axios
      .post('/api/companies/' + this.id + '/remove-payment-method')
      .then((response) => {
        // Company.update({
        //   where: this.id,
        //   data: {
        //     card_brand: response.data.company.card_brand,
        //     card_last_four: response.data.company.card_last_four
        //   }
        // })
        return response.data
      })
  }

  $subscribe () {
    return Vue.prototype.$axios
      .post('/api/companies/' + this.id + '/subscribe')
      .then((response) => {
        return response.data
      }).catch((error) => {
        throw error
      })
  }

  $getCheckoutSession () {
    return Vue.prototype.$axios
      .get('/api/checkout')
      .then((response) => {
        return response.data
      }).catch((error) => {
        throw error
      })
  }

  $getBillingPortalSession () {
    return Vue.prototype.$axios
      .get('/api/billing-portal-session')
      .then((response) => {
        return response.data
      }).catch((error) => {
        throw error
      })
  }
}
