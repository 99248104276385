// Project Model
import VuexORM from '@vuex-orm/core'
import Company from './Company'

export default class Invoice extends VuexORM.Model {
  // This is the name used as module name of the Vuex Store.
  static get entity () {
    return 'invoices'
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      company_id: this.attr(''),
      company: this.belongsTo(Company, 'company_id'),
      number: this.attr(),
      date: this.attr(''),
      amount: this.number(0),
      gross_amount: this.number(0),
      description: this.attr(''),
      fatture_in_cloud_id: this.attr('')
    }
  }

  static apiConfig = {
    actions: {
      sendThroughFattureInCloud (companyId, id) {
        return this.get(`/api/companies/${companyId}/invoices/${id}/send-through-fic`)
      }
    }
  }
}
